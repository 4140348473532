import { memo } from "react";

import BB_Button from "@/Components/BB_Button";
import HelpIcon from "@/Icons/help.svg?react";
import { generateMailtoLink } from "@/Utils/MailtoGenerator";

const isSupportEnabled: boolean = import.meta.env.VITE_SUPPORT_ENABLED === "true";

const BB_FloatingButton = () => {
	return isSupportEnabled ? (
		<BB_Button
			id="floatingButton"
			type={"plain"}
			icon={HelpIcon}
			ripple={true}
			iconClassName="h-7 w-7 text-black pointer-events-none"
			buttonClassName="z-29 fixed bottom-[calc(75px_+_env(safe-area-inset-bottom))] sm:bottom-4 right-4 p-3 rounded-full shadow-lg bg-primary-500 dark:bg-dark-primary-400 transition-all"
			onClick={generateMailtoLink(
				"Problem melden",
				"Hey Bestell.bar-Team,\n\nIch habe ein Problem und brauche Hilfe.\n\nBeschreibung des Problems:\n\n[Bitte füge hier eine detaillierte Beschreibung des Problems hinzu.]\n\nBeste Grüße"
			)}
			alt="Problem melden"
		/>
	) : null;
};

export default memo(BB_FloatingButton);
